import { Box } from "@mui/material";
import { createBrowserHistory } from 'history';
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { useBrowserEvent } from "./hooks/useBrowserEvent";
import { routes } from './routes';
import BuilderIO from "./screens/BuilderIO";


function App() {
  const history = createBrowserHistory({});

  return (
    <BrowserRouter history={history}>
      <Box sx={{
        overflowX: "hidden",
        height: "100vh",
        background: "#0e1c2f",
      }}>
        <AppRouter />
      </Box>
    </BrowserRouter>
  );
}


const AppRouter = () => {
  const params = useLocation();
  const [showNavBar, setShowNavBar] = useState(true)

  useEffect(() => {
    // scroll to page top
    window.scrollTo(0, 0)
  }, [params])

  useBrowserEvent('toggleNavBar', (event) => {
    setShowNavBar(event.detail.show)
  })

  return (
    <>
      {showNavBar && <Navbar />}
      <Routes>
        {routes.map((route, i) => {
          return <Route key={i} path={route.path} element={<route.component />} />
        })}
        <Route path={"/*"} element={<BuilderIO />} />
        {/* <Route path={"/*"} element={<MaintenancePage />} /> */}
      </Routes>
    </>
  )
}

export default App;
