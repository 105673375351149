import Axios from 'axios';

class API {
    constructor(token, host) {
        this.token = token;
        this.host = host || "/api/v1";
        this.headers = {}
        this.includes = []
        this.queries = []
        this.limit = 10
    }

    addHost(host) {
        this.host = host
    }

    addHeader(key, value) {
        this.headers[key] = value;
    }

    include(includes) {
        this.includes = includes;
    }

    query(queries) {
        this.queries = queries;
    }

    setLimit(num) {
        this.limit = num;
    }

    urlBuilder(method, path) {
        if (method !== "get") return `${this.host}/${path}`
        const prefix = path?.includes("?") ? "&" : "?"
        if (!path.includes("limit")) path += `${prefix}$limit=${this.limit}`
        const params = [{ name: "includes", op: "include" }, { name: "queries", op: "or" }]
        for (const param of params) {
            if (this[param.name].length > 0) {
                const op = `&$${param.op}=`
                path += op + this[param.name].join(op)
            }
        }
        return `${this.host}/${path}`
    }

    request(method, path, payload = null) {
        const { token, host } = this;
        const allowedMethods = ["post", "patch", "delete", "get"];
        const endpoint = this.urlBuilder(method, path);
        return new Promise((resolve, reject) => {
            try {
                let config = { headers: this.headers }
                if (token) config["headers"]["Authorization"] = `Bearer ${token}`;
                if (payload) config['data'] = payload
                let op = allowedMethods.splice(0, 2).includes(method) ?
                    Axios[method](endpoint, config.data, config)
                    :
                    Axios[method](endpoint, config)
                // make the request
                op.then(res => {
                    resolve(res?.data);
                }).catch(error => {
                    reject(new Error(this.NetworkErrorHandler(error)));
                });
            } catch (error) {
                reject(new Error(this.NetworkErrorHandler(error)))
            }
        })
    }

    NetworkErrorHandler = (error) => {
        if (error.response) return (error?.response?.data?.message)
        if (error.request) return (error?.request?.statusText)
        return (error?.message)
    }
}

export default API