import { Alert, Avatar, Box, Button, Card, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import { CiLock, CiUser } from "react-icons/ci";
import { Col, Form, Row } from "reactstrap";
import API from "../services/API";

import ErrandzLogo from "../assets/images/ErrandzLogoBlack.png";

const DeleteAccount = (props) => {
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [user, setUser] = useState(null);
    const [loginPayload, setLoginPayload] = useState({
        username: '',
        password: ''
    });

    useEffect(() => {
        const customEvent = new CustomEvent('toggleNavBar', {
            detail: { show: false }
        });

        setTimeout(() => {
            window.dispatchEvent(customEvent);
        }, 100);

        return () => {
            const customEvent = new CustomEvent('toggleNavBar', {
                detail: { show: true }
            });
            window.dispatchEvent(customEvent);
        }
    }, [])

    const handleLogin = useCallback(async (e) => {
        try {
            e.preventDefault();
            if (!loginPayload?.username?.trim()) throw new Error("Username is required")
            if (!loginPayload?.password?.trim()) throw new Error("Password is required")
            setLoading(true)
            const api = new API();
            const response = await api.request("post", "b2b/users/login", loginPayload)
            if (response.error) throw new Error(response.error ?? "Error encountered while logging in")
            setUser(response)
            setLoading(false)
        } catch (error) {
            alert(error.message);
            setLoading(false)
        }
    }, [loginPayload])

    const handleDeleteAccount = useCallback(async (e) => {
        try {
            e.preventDefault();
            if (!user?.sessionToken) throw new Error("You must be logged in")
            const api = new API();
            const { result } = await api.request("delete", "b2b/users", {
                sessionToken: user.sessionToken,
                userId: user.objectId
            })
            if (result.error) throw new Error(result.error ?? "Error encountered while deleting your account")
            setLoading(false)
            setDeleted(true)
        } catch (error) {
            alert(error.message);
            setLoading(false)
        }
    }, [user])

    return (
        <Box sx={{ height: "100%", background: "#FFF", flexDirection: 'column', paddingTop: '30px', display: 'flex', }}>
            <img src={ErrandzLogo} alt="Logo" style={{ width: 150, margin: '10px auto' }} />
            <Box mb={5}>
                <Typography component="h1" fontSize={25} textAlign={'center'}>Account Deletion Wizard</Typography>
            </Box>

            <Row style={{ display: "flex", alignItems: "center", }}>
                <Col md={"6"} className="mx-auto">
                    <Card sx={{ p: 1 }}>
                        {!user ? <Form onSubmit={handleLogin}>
                            <Box sx={{ mb: 1 }}>
                                <Typography component={"h1"} sx={{ lineHeight: "10px", mt: 1 }}>Step 1 / 2</Typography>
                                <Typography component={"h3"} sx={{ lineHeight: "30px", opacity: "0.7" }}>Login to confirm your Identity</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ mt: 2 }}>
                                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                                    <Typography component={"label"} sx={{ fontSize: "13px", lineHeight: "10px", opacity: "0.7" }}>Username</Typography>
                                    <TextField
                                        id="outlined-start-adornment"
                                        defaultValue={loginPayload.username}
                                        onChange={e => setLoginPayload((prev) => ({ ...prev, username: e.target.value }))}
                                        sx={{ width: '98%', mt: 1 }}
                                        InputProps={{
                                            inputMode: "tel",
                                            startAdornment: <InputAdornment position="start"><CiUser /></InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                                    <Typography component={"label"} sx={{ fontSize: "13px", lineHeight: "10px", opacity: "0.7" }}>Password</Typography>
                                    <TextField
                                        id="outlined-start-adornment"
                                        type="password"
                                        defaultValue={loginPayload.password}
                                        onChange={e => setLoginPayload((prev) => ({ ...prev, password: e.target.value }))}
                                        sx={{ width: '98%', mt: 1 }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><CiLock /></InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Button variant="contained" color="warning" type="submit" sx={{ width: "100%", my: 1 }} disabled={loading}>{loading ? 'Please Wait...' : 'Login'}</Button>
                            </Box>
                        </Form>
                            :
                            <Box>
                                <Typography component={"h1"} sx={{ lineHeight: "10px", mt: 1 }}>Step 2 / 2</Typography>
                                <Typography component={"h3"} sx={{ lineHeight: "30px", opacity: "0.7" }}>Confirm operation</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 5 }}>
                                    <Avatar alt={"avatar"} sx={{ width: 100, height: 100 }} />
                                    <Typography component={"h1"} sx={{ lineHeight: "10px", mt: 1, fontSize: 20 }}>{user.name}</Typography>
                                    <Typography component={"h5"} sx={{ lineHeight: "10px", mt: 1 }}>{user.email}</Typography>
                                    {deleted ?
                                        <Alert variant="standard" severity="info" sx={{ mt: 2, width: '100%' }}>
                                        <Typography component={"h1"} sx={{ lineHeight: "10px", fontSize: 13, fontWeight: 700 }}>Account Deleted</Typography>
                                        <Typography component={"h1"} sx={{ lineHeight: "15px", mt: 1, fontSize: 13 }}>
                                            Your account has been deleted successfully.
                                        </Typography>
                                    </Alert>
                                        :
                                        <>
                                            <Alert variant="standard" severity="error" sx={{ mt: 2, width: '100%' }}>
                                                <Typography component={"h1"} sx={{ lineHeight: "10px", fontSize: 13, fontWeight: 700 }}>DANGER ZONE</Typography>
                                                <Typography component={"h1"} sx={{ lineHeight: "15px", mt: 1, fontSize: 13 }}>
                                                    Are you sure you want to delete your account? This action cannot be undone.<br />
                                                    All your data associated with this account will be permanently deleted.<br /><br />
                                                    <strong>Proceed with caution</strong>
                                                </Typography>
                                            </Alert>
                                            <Box sx={{ mt: 2, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                <Button variant="contained" color="inherit" onClick={() => setUser()}>Back</Button>
                                                <Button variant="contained" color="error" disabled={loading} onClick={handleDeleteAccount}>{loading ? 'Please Wait...' : 'Delete Account'}</Button>
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Box>
                        }
                    </Card>
                </Col>
            </Row>
        </Box>
    )
}

export default DeleteAccount;