import React, { useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const Landing = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/home")
    }, [])

    return (
        <Box>
            <Typography component={"h1"}>Redirecting...</Typography>
        </Box>
    )
}

export default Landing;