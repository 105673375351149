import styled from 'styled-components';

export const StyledBrand = styled.div`
  img {
    max-height: 65px;
    width: 170px;
    object-fit: cover;
  }
  @media only screen and (max-width: 380px) {
    img {
      max-height: 47px;
    }
  }
`;
