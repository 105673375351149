import styled from "styled-components";

// const location = window.location.pathname;
// location !== "track" && "position: fixed;";

export const StyledNavContainer = styled.nav`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0px;

  background-color: #0F021B;
  z-index: 999;
  color: #FFF;
  @media only screen and (max-width: 380px) {
    padding: 15px 0.5rem;
  }
`;
