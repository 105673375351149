// create a react component that redirects to an external url
import React, { useEffect } from "react";

const ChatBungee = () => {
    useEffect(() => {
        window.location.href = "https://bit.ly/chat-bungee";
    })
    
    return null
}

export default ChatBungee;