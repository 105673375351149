import ChatBungee from "../screens/Chat";
import DeleteAccount from "../screens/DeleteAccount";
import FundWallet from "../screens/FundWallet";
import IdentityVerification from "../screens/IdentityVerification";
import Landing from "../screens/Landing";

export const routes = [
    {
        name: "home",
        path: "/",
        component: Landing
    },
    {
        name: "fund-wallet",
        path: "fund-wallet",
        component: FundWallet
    },
    {
        name : "chat-bungee",
        path : "/chat",
        component : ChatBungee
    },
    {
        name: "verify",
        path: "verify",
        component: IdentityVerification
    },
    {
        name: "delete-account",
        path: "delete-errandz-account",
        component: DeleteAccount
    },
]