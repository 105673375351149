import React from "react";
import { StyledHamburger } from "./style";

const Hamburger = ({ onClick }) => {
  return (
    <StyledHamburger onClick={onClick}>
      <svg
        className="hamsvg"
        fill="#64378C"
        stroke="#f4be03"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 12h16m-7 6h7"
        />
      </svg>
    </StyledHamburger>
  );
};

export default Hamburger;
