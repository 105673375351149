import styled from 'styled-components';

export const StyledHamburger = styled.div`
  cursor: pointer;
  display: none;
  border: 1px solid #f4be03;
  border-radius: 2px;
  padding: 5px;
  .hamsvg {
    width: 1.5rem;
    height: 1.5rem;
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`;
