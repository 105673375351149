import React, { useState } from "react";
import { StyledNavContainer } from "./Styles";
import Hamburger from "../Hamburgerbtn/Hamburger";
import NavMenu from "../NavMenu/NavMenu";
import Brand from "../Brand/Brand";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledNavContainer>
      <Brand />
      <Hamburger onClick={toggle} />
      <NavMenu isOpen={isOpen} />
    </StyledNavContainer>
  );
};

export default Navbar;
