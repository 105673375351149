import styled from 'styled-components';

export const StyledMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  padding: 1rem 0.5rem;

  a {
    padding: 0 0.5rem;
    text-decoration: none;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFF;
    :visited {
      color: #FFF;
    }
  }

  a:hover {
  }

  button {
    border: none;
    border-radius: 5px;
    color: #ffff;
    padding: 10px;
    background-color: #64378c;
    margin: 0 0.5rem;
  }

  @media only screen and (max-width: 1024px) {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    ${({ isOpen }) => (isOpen ? 'display:flex;' : 'display: none;')};

    a {
      padding: 1rem 0.5rem;
    }
  }
`;
