const { default: styled } = require("styled-components");

export const StyledButton = styled.button`
  padding: ${props => props.padding || "10px"};
  border-radius: 5px;
  background-color: #d7d7d7;
  margin: 10px;
  border-width: 0px;
  font-size: 13px;

  &:hover{
      cursor: pointer;
      box-shadow: -3px 2px 10px 1px #33333391;
  }
`;
