import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Typography, TextField, InputAdornment, Avatar, Alert, IconButton, CircularProgress } from "@mui/material";
import { Col, Form, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import API from "../services/API";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const phone_regex = /^[\+]?[(]?[0-9]{9,13}[)]?$/;

const IdentityVerification = (props) => {
    const [loading, setLoading] = useState(true);
    const [ivs, setIvs] = useState(null)
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [phone, setPhone] = useState("");
    const params = useLocation();

    useEffect(() => {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        const id = parameters.get('q');
        const fetchData = async () => {
            try {
                const api = new API();
                const response = await api.request("get", `users/ivs?id=${id}`);
                setIvs(response);
                setLoading(false)
            } catch (error) {
                console.log(error.message);
                setError(error.message)
                setLoading(false)
            }
        }

        fetchData();
    }, [params])

    const submit = useCallback(async (e) => {
        try {
            e.preventDefault();
            if (!phone_regex.test(phone)) throw Error("Please input a valid phone number");
            if (!ivs?.signature) throw Error("Please provide a valid signature")
            setLoading(true)
            const queryString = window.location.search;
            const parameters = new URLSearchParams(queryString);
            const id = parameters.get('q');

            const api = new API();
            await api.request("post", "users/ivs/verify", { id, phone, signature: ivs?.signature })
            setLoading(false)
            setSuccess(true)
        } catch (error) {
            setLoading(false)
            setError(error.message);
        }
    }, [phone, ivs])


    return (
        <Row style={{ height: "calc(100% - 60px)", marginTop: '100px', display: "flex", alignItems: "center", overflowY: 'auto' }}>
            <Col md={"6"} sm={'10'} xs={'11'} className="mx-auto">
                <Card sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    '@media only screen and (max-width: 720px)': {
                        my: 'auto'
                    }
                }}>
                    {loading ?
                        <CircularProgress sx={{
                            width: 100,
                            height: 100,
                            my: 1,
                            alignSelf: 'center'
                        }} />
                        :
                        error ?
                        <Alert variant="outlined" color="error"><Typography variant="h6" component="h6">Verification Error</Typography>{error}</Alert>
                        :
                        success ?
                        <Alert variant="outlined" color="success"><Typography variant="h6" component="h6">Verification Successful!</Typography>Your have completed your identity verification.<br /><br />Please close this window and reopen the Dispatch-Z / Errandz mobile app</Alert>
                        :
                        <Form onSubmit={submit}>
                            <Box sx={{ mb: 1 }}>
                                <Typography component={"h1"} sx={{ lineHeight: "20px", mt: 1 }}>Complete Identity Verification</Typography>
                                <Typography component={"label"} sx={{ fontSize: "13px", lineHeight: "15px", opacity: "0.7" }}>Kindly fill the form below to complete you identity verification</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ mt: 2 }}>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                                    <Avatar src={ivs?.userPhotoUrl} sx={{
                                        width: 200,
                                        height: 200,
                                        mx: 'auto'
                                    }} />
                                    <Typography component={"h5"} variant="h5" sx={{ mt: 2 }}>{ivs?.fullname}</Typography>
                                </Box>
                                <Alert variant="outlined" color="info" sx={{ my: 2 }}>Please input the phone that is associated with you Dispatch-Z or Errandz account to complete your identification process</Alert>
                                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                                    <TextField
                                        id="outlined-start-adornment"
                                        defaultValue={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        sx={{ width: '98%', mt: 1 }}
                                        placeholder="+2348100000000"
                                        helperText={""}
                                        InputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                            startAdornment: <InputAdornment position="start"><LocalPhoneIcon /></InputAdornment>,
                                        }}
                                    />
                                </Box>
                                <Button variant="contained" color="success" type="submit" disabled={!ivs} sx={{ width: "100%", my: 1 }}>Verify</Button>
                            </Box>
                        </Form>}
                </Card>
            </Col>
        </Row>
    )
}

export default IdentityVerification;