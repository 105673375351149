
import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { BUILDER } from "../constants/enums";
import { useNavigate } from 'react-router';

// Put your API key here
builder.init(BUILDER.API_KEY);

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function BuilderIO() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const navigate = useNavigate();

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname
        })
        .promise();

      setContent(content);
      setNotFound(!content);
    }
    fetchContent();
  }, [window.location.pathname]);

  useEffect(() => {
    const title = content?.data.title;
    document.title = title ? `Dispatch-Z | ${title}` : document.title;
  }, [content])
  
  // if no page is found, return a 404 page
  if (notFound && !isPreviewingInBuilder) {
    navigate("/home")
  }

  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={content} />
    </>
  );
}