import React from "react";
import { StyledBrand } from "./style";
import { Link } from "react-router-dom";

const Brand = () => {
  return (
    <StyledBrand>
      <Link to="/">
        <div style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          width: 200,
          height: 80,
          backgroundImage: `url(https://github.com/zheeno/assets/blob/main/img/logos/DZ_Logo_Yellow_Transparent.png?raw=true)`
        }} />
      </Link>
    </StyledBrand>
  );
};

export default Brand;
