import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import { StyledMenu } from "./style";

const menus = [
  {
    to: "/",
    name: "Home",
    id: 1
  },
  {
    to: "/about",
    name: "About",
    id: 6
  },
  {
    to: "/partner",
    name: "Partner ",
    id: 4
  }, 
  {
    to: "/errandz",
    name: "Errandz",
    id: 7
  }
];
const NavMenu = ({ isOpen }) => {
  return (
    <StyledMenu isOpen={isOpen}>
      {menus.map((menu, i) => (
        <Link to={menu.to} key={i}>
          {menu.name}
        </Link>
      ))}
      {/* Download Btn */}
      <Button onClick={() => window.location.assign("/download")}>
        DOWNLOAD
      </Button>
    </StyledMenu>
  );
};

export default NavMenu;
