import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Divider, Typography, TextField, InputAdornment } from "@mui/material";
import { Col, Form, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import API from "../services/API";

const FundWallet = (props) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [amount, setAmount] = useState("");
    const params = useLocation();

    useEffect(() => {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        const id = parameters.get('q');
        const fetchData = async () => {
            try {
                const api = new API();
                const response = await api.request("get", `wallet/initialize?user=${id}`);
                setUser(response);
                setLoading(false)
            } catch (error) {
                console.log(error.message);
            }
        }

        fetchData();
    }, [params])

    const submit = useCallback(async (e) => {
        try {
            e.preventDefault();
            if (Number(amount) < 100) throw Error("Amount must be at least NGN 100.00");
            const api = new API();
            const response = await api.request("post", "wallet/fund", {
                id: user.id,
                email: user.email,
                amount: Number(amount)
            })
            window.location = response?.authorization_url
        } catch (error) {
            console.log(error.message);
        }
    }, [user, amount])


    return (
        <Row style={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Col md={"6"} className="mx-auto">
                <Card sx={{ p: 1 }}>
                    <Form onSubmit={submit}>
                        <Box sx={{ mb: 1 }}>
                            <Typography component={"h1"} sx={{ lineHeight: "10px", mt: 1 }}>Fund Wallet</Typography>
                            <Typography component={"label"} sx={{ fontSize: "13px", lineHeight: "10px", opacity: "0.7" }}>Fund your Dispatch-Z wallet with ease</Typography>
                        </Box>
                        <Divider />
                        <Box sx={{ mt: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography component={"label"} sx={{ fontSize: "13px", lineHeight: "10px", opacity: "0.7" }}>Account Name</Typography>
                                <Typography component={"p1"}>{user?.name}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                                <Typography component={"label"} sx={{ fontSize: "13px", lineHeight: "10px", opacity: "0.7" }}>Amount</Typography>
                                <TextField
                                    id="outlined-start-adornment"
                                    defaultValue={amount}
                                    onChange={e => setAmount(e.target.value)}
                                    sx={{ width: '98%', mt: 1 }}
                                    helperText={"Minimum amount #100.00"}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        startAdornment: <InputAdornment position="start">&#8358;</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Button variant="contained" color="warning" type="submit" sx={{ width: "100%", my: 1 }}>Fund Wallet</Button>
                        </Box>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default FundWallet;